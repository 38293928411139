<template>
  <home-header
    @fixed="fixed = $event"
    :social_media="social_media"
  ></home-header>
  <div v-if="fixed" class="div-pad"></div>
  <r-content :moved="$r.store.openMenu">
    <router-view v-slot="{ Component }">
      <transition name="slide-down" mode="out-in">
        <keep-alive :exclude="['singleDoctor', 'singlePlace']">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </r-content>
  <home-footer
    :social_media="social_media"
    :contact_us="contact_us"
  ></home-footer>
</template>

<script>
import HomeFooter from "../components/homeFooter";
import HomeHeader from "../components/homeHeader";

export default {
  name: "home",
  components: { HomeHeader, HomeFooter },
  data() {
    return {
      fixed: false,
      social_media: {},
      contact_us: {},
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get("home/settings/multi/contact_us,social_media")
        .then(({ data }) => {
          for (let i = 0; i < data.length; i++) {
            this[data[i]["name"]] = data[i]["value"];
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/_include";
.r-content {
  transition: all 1s map-get($transition, "swing") !important;
}
.div-pad {
  height: $toolbar-height;
}
</style>
