<template>
  <div
    class="home-header"
    :class="{ 'sidebar-open': $r.store.openMenu, 'header-fixed': fixed }"
    v-scroll="check"
  >
    <div class="header-top">
      <r-container>
        <r-row>
          <r-col class="col-auto h-center" v-if="$r.breakpoint.lgAndUp">
            <r-icon
              class="color-two-text"
              height="50"
              width="50"
              v-html="
                '<path fill=&quot;currentColor&quot; d=&quot;M18 15a3 3 0 0 1 3 3a3 3 0 0 1-3 3a2.99 2.99 0 0 1-2.83-2H14v-2h1.17c.41-1.17 1.52-2 2.83-2m0 2a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m0-9a1.43 1.43 0 0 0 1.43-1.43a1.43 1.43 0 1 0-2.86 0A1.43 1.43 0 0 0 18 8m0-5.43a4 4 0 0 1 4 4C22 9.56 18 14 18 14s-4-4.44-4-7.43a4 4 0 0 1 4-4M8.83 17H10v2H8.83A2.99 2.99 0 0 1 6 21a3 3 0 0 1-3-3c0-1.31.83-2.42 2-2.83V14h2v1.17c.85.3 1.53.98 1.83 1.83M6 17a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M6 3a3 3 0 0 1 3 3c0 1.31-.83 2.42-2 2.83V10H5V8.83A2.99 2.99 0 0 1 3 6a3 3 0 0 1 3-3m0 2a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m5 14v-2h2v2h-2m-4-6H5v-2h2v2Z&quot;/>'
              "
            ></r-icon>
            <div class="ms-1">
              <span class="color-one-text">{{ $t("visit_any_time") }}</span>
              <div>{{ $t("all_city") }}</div>
            </div>
          </r-col>
          <r-col class="col-auto d-flex" v-if="$r.breakpoint.mdAndUp">
            <r-icon
              class="color-two-text"
              height="50"
              width="50"
              viewBox="0 0 32 32"
              v-html="
                '<path fill=&quot;none&quot; stroke=&quot;currentcolor&quot; stroke-linecap=&quot;round&quot; stroke-linejoin=&quot;round&quot; stroke-width=&quot;2&quot; d=&quot;M21 2H11c-1 0-2 1-2 2v24c0 1 1 2 2 2h10c1 0 2-1 2-2V4c0-1-1-2-2-2ZM9 5h14M9 27h14&quot;/>'
              "
            ></r-icon>
            <div class="ms-1">
              <span class="color-one-text">{{ $t("contact_phone") }}</span>
              <div>+98914444444</div>
            </div>
          </r-col>
          <r-col class="col-auto h-center" v-if="$r.breakpoint.mdAndUp">
            <r-icon
              class="color-two-text"
              height="50"
              width="50"
              viewBox="0 0 32 32"
              v-html="
                '<g fill=&quot;currentColor&quot;><path d=&quot;M16 32c8.822 0 16-7.178 16-16S24.822 0 16 0S0 7.178 0 16s7.178 16 16 16zm0-31c8.271 0 15 6.729 15 15s-6.729 15-15 15S1 24.271 1 16S7.729 1 16 1z&quot;/><path d=&quot;M20.061 21.768a.498.498 0 0 0 .708 0a.5.5 0 0 0 0-.707L16 16.293V9.319a.5.5 0 0 0-1 0V16.5c0 .133.053.26.146.354l4.915 4.914z&quot;/><circle cx=&quot;4&quot; cy=&quot;16&quot; r=&quot;1&quot;/><circle cx=&quot;28&quot; cy=&quot;16&quot; r=&quot;1&quot;/><circle cx=&quot;16&quot; cy=&quot;4&quot; r=&quot;1&quot;/><circle cx=&quot;16&quot; cy=&quot;28&quot; r=&quot;1&quot;/><circle cx=&quot;8&quot; cy=&quot;8&quot; r=&quot;1&quot;/><circle cx=&quot;24&quot; cy=&quot;24&quot; r=&quot;1&quot;/><circle cx=&quot;25&quot; cy=&quot;8&quot; r=&quot;1&quot;/><circle cx=&quot;8&quot; cy=&quot;24&quot; r=&quot;1&quot;/></g>'
              "
            ></r-icon>

            <div class="ms-1">
              <span class="color-one-text">{{ $t("working_time") }}</span>
              <div>7/24</div>
            </div>
          </r-col>
          <r-spacer v-if="$r.breakpoint.mdAndUp"></r-spacer>
          <r-col class="col-12 md-auto h-space-between d-flex v-center">
            <r-btn
              :to="{ name: 'dashboard' }"
              text
              icon
              v-if="!$r.store.user.login"
            >
              <r-icon
                class="color-two-text"
                height="25"
                width="25"
                viewBox="0 0 1024 1024"
                v-html="
                  '<path fill=&quot;currentColor&quot; d=&quot;M532.528 661.408c-12.512 12.496-12.513 32.752-.001 45.248c6.256 6.256 14.432 9.376 22.624 9.376s16.368-3.12 22.624-9.376l189.008-194L577.775 318.64c-12.496-12.496-32.752-12.496-45.248 0c-12.512 12.496-12.512 32.752 0 45.248l115.744 115.76H31.839c-17.68 0-32 14.336-32 32s14.32 32 32 32h618.448zM960.159 0h-576c-35.36 0-64.017 28.656-64.017 64v288h64.432V103.024c0-21.376 17.344-38.72 38.72-38.72h496.704c21.408 0 38.72 17.344 38.72 38.72l1.007 818.288c0 21.376-17.311 38.72-38.72 38.72H423.31c-21.376 0-38.72-17.344-38.72-38.72V670.944l-64.432.08V960c0 35.344 28.656 64 64.017 64h576c35.344 0 64-28.656 64-64V64c-.016-35.344-28.672-64-64.016-64z&quot;/>'
                "
              ></r-icon>
            </r-btn>
            <span v-else>
              <r-btn :to="{ name: 'dashboard' }" text icon>
                <r-icon
                  class="color-two-text"
                  height="25"
                  width="25"
                  viewBox="0 0 32 32"
                  v-html="
                    '<path fill=&quot;currentColor&quot; d=&quot;M24 21h2v5h-2zm-4-5h2v10h-2zm-9 10a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z&quot;/><path fill=&quot;currentColor&quot; d=&quot;M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm0 9H14V4h14ZM12 4v7H4V4ZM4 28V13h24l.002 15Z&quot;/>'
                  "
                ></r-icon>
              </r-btn>
              <r-btn
                v-if="$r.store.user.info.permissions.includes('doctor')"
                href="/doctor"
                target="_blank"
                text
                icon
              >
                <r-icon
                  class="color-success-text"
                  height="25"
                  width="25"
                  viewBox="0 0 24 24"
                  v-html="
                    '<path fill=&quot;currentColor&quot; d=&quot;M3 3h8v8H3V3zm10 0h8v8h-8V3zM3 13h8v8H3v-8zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z&quot;/>'
                  "
                ></r-icon>
              </r-btn>
            </span>
            <r-img
              src="/storage/img/site/logo.png"
              alt="brand"
              width="150"
              height="50"
            ></r-img>
          </r-col>
        </r-row>
      </r-container>
    </div>
    <r-divider></r-divider>
    <header>
      <r-container class="d-flex v-center">
        <template v-if="$r.breakpoint.lgAndUp">
          <r-btn text v-for="(item, i) in menu" :key="i" :to="item.to"
            >{{ $t(item.name) }}
          </r-btn>
        </template>
        <template v-else>
          <r-btn icon text @click="$r.store.openMenu = !$r.store.openMenu">
            <r-icon
              v-html="$r.store.openMenu ? $r.icons.close : $r.icons.menu"
            ></r-icon>
          </r-btn>
        </template>
        <r-spacer></r-spacer>
        <r-btn
          v-for="(item, i) in social_media"
          :key="i"
          icon
          class="ma-2 color-primary-text"
          :href="item"
          target="_blank"
          text
        >
          <img :src="`/storage/img/logos/${i}.png?w=30`" alt="i" />
        </r-btn>
      </r-container>
    </header>
    <r-divider></r-divider>
    <side-bar
      class="header-side-bar color-two text-start"
      :items="menu"
    ></side-bar>
  </div>
</template>

<script>
import SideBar from "./sideBar";

export default {
  name: "homeHeader",
  components: { SideBar },
  props: {
    social_media: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      fixed: false,
      menu: [
        { name: "home", to: { name: "base" } },
        { name: "doctors", to: { name: "doctors" } },
        { name: "places", to: { name: "places" } },
        { name: "register_doctor", to: { name: "registerDoctor" } },
        { name: "about_us", to: { name: "aboutUs" } },
      ],
    };
  },
  methods: {
    check() {
      if (window.scrollY >= 100) {
        this.fixed = true;
        this.$emit("fixed", true);
      } else {
        this.fixed = false;
        this.$emit("fixed", false);
      }
    },
  },
  created() {
    this.$r.store.openMenu = false;
  },
  watch: {
    $route: function () {
      this.$r.store.openMenu = false;
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";

.home-header {
  $transition: all 1s map-get($transition, "swing");
  background-color: white;
  width: 100%;
  transition: $transition;
  &.header-fixed {
    header {
      background-color: white;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 2;
      @include elevation("sm");
    }
  }
  .r-container {
    transition: $transition;
  }
  .header-side-bar {
    position: fixed;
    top: 0;
    @include rtl() {
      right: -$navbar-width;
    }
    @include ltr() {
      left: -$navbar-width;
    }

    width: $navbar-width;
    height: 100vh;
    z-index: map_get($z_index, "important");
    transition: $transition;
  }

  &.sidebar-open {
    width: calc(100% + #{$navbar-width});
    @include rtl() {
      margin-right: $navbar-width;
    }
    @include ltr() {
      margin-left: $navbar-width;
    }
    .header-side-bar {
      @include rtl() {
        right: 0;
      }
      @include ltr() {
        left: 0;
      }
    }
    .r-container {
      max-width: 100% !important;
    }
  }
}
</style>
