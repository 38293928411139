import { createRouter, createWebHistory } from "vue-router";
import { guest, auth } from "./guards";

import IndexLayout from "../layouts/home";
import UserLayout from "../layouts/user";

const login = () => import("renusify/renus/user/login");
const law = () => import("renusify/renus/law");
/* {{place new import}} */
const base = () => import("../views/index/home");
const places = () => import("../views/index/places");
const doctors = () => import("../views/index/doctors");
const registerDoctor = () => import("../views/index/registerDoctor");
const singleDoctor = () => import("../views/index/singleDoctor");
const singlePlace = () => import("../views/index/singlePlace");
const notFound = () => import("../views/index/notFound");
const paymentVerify = () => import("../views/index/paymentVerify");
const aboutUs = () => import("../views/index/about_us");
/* user panel*/
const dashboard = () => import("../views/index/user/index");
const tickets = () => import("renusify/renus/ticket/index_user");
const profile = () => import("renusify/renus/user/profile");
const visits = () => import("../views/index/user/visits");
const settings = () => import("renusify/renus/setting");

/* static pages */
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
    beforeEnter: (to, from, next) => {
      guest(to, from, next);
    },
  },
  {
    path: "/laws",
    name: "laws",
    component: law,
  },
  {
    path: "/user",
    component: UserLayout,
    beforeEnter: (to, from, next) => {
      auth(to, from, next);
    },
    children: [
      { path: "", name: "dashboard", component: dashboard },
      { path: "profile", name: "profile", component: profile },
      { path: "tickets", name: "tickets", component: tickets },
      { path: "settings", name: "settings", component: settings },
      { path: "visits", name: "visits", component: visits },
    ],
  },
  {
    path: "/",
    component: IndexLayout,
    children: [
      { path: "", name: "base", component: base },
      {
        path: "/home/places/:state?/:city?",
        name: "places",
        component: places,
        props: true,
      },
      {
        path: "/home/doctors/:state?/:city?/:special?",
        name: "doctors",
        component: doctors,
        props: true,
      },
      {
        path: "/home/doctors/register",
        name: "registerDoctor",
        component: registerDoctor,
      },
      {
        path: "/home/doctors/show/:doctor_id",
        name: "singleDoctor",
        component: singleDoctor,
        props: true,
      },
      {
        path: "/home/places/show/:place_id",
        name: "singlePlace",
        component: singlePlace,
        props: true,
      },
      {
        path: "/home/payment/:status/:refId",
        name: "paymentVerify",
        component: paymentVerify,
        props: true,
      },
      {
        path: "/home/about-us",
        name: "aboutUs",
        component: aboutUs,
      },
      { path: "/:pathMatch(.*)*", name: "not_found", component: notFound },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production"
      ? process.env.API_production_URL
      : process.env.API_URL
  ),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        setTimeout(() => {
          resolve({ left: 0, top: savedPosition["top"] });
        }, 500);
      } else {
        resolve({ left: 0, top: 0 });
      }
    });
  },
});

export default router;
