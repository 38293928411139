<template>
  <footer class="home-footer">
    <div class="footer-wave">
      <img src="/storage/img/site/blue_bottom_wave_01.png" alt="wave2" />
    </div>
    <r-container>
      <r-row>
        <r-col class="col-12 md-4">
          <r-img
            src="/storage/img/site/logo.png"
            alt="doctor-logo"
            width="200"
            height="100"
          ></r-img>
          <p>{{ $t("home_about_msg") }}</p>
        </r-col>
        <r-col class="col-12 md-4">
          <div class="display-5">{{ $t("contact_us") }}</div>
          <r-divider class="color-warning my-2"></r-divider>
          <div class="title" v-for="(item, k) in contact_us" :key="k">
            {{ $t(k) }}: {{ item }}
          </div>

          <div>
            <r-btn
              v-for="(item, i) in social_media"
              :key="i"
              icon
              class="ma-2 color-primary-text"
              v-title="i"
              :href="item"
              target="_blank"
            >
              <img :src="`/storage/img/logos/${i}.png?w=30`" alt="i" />
            </r-btn>
          </div>
        </r-col>
        <r-col class="col-12 md-4 title">
          <div class="display-5">{{ $t("useful_links") }}</div>
          <r-divider class="color-warning my-2"></r-divider>
          <router-link :to="{ name: 'doctors' }">{{
            $t("doctors")
          }}</router-link>
          <br />
          <router-link :to="{ name: 'places' }">{{ $t("places") }}</router-link>
        </r-col>
      </r-row>
    </r-container>
    <r-container full-width class="copyright">
      <r-row class="h-center no-gutters">
        <r-col class="col-auto">
          {{ $t(["footer_copyright_msg", new Date().getFullYear()]) }}
        </r-col>
      </r-row>
    </r-container>
  </footer>
</template>

<script>
export default {
  name: "homeFooter",
  props: {
    social_media: {
      type: Object,
      default: () => {
        return {};
      },
    },
    contact_us: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~renusify/style/include";

.home-footer {
  position: relative;
  margin-top: 50px;
  background-color: #175c62 !important;
  color: white;
  * {
    color: white !important;
  }

  .copyright {
    background-color: black;
  }
  .footer-wave {
    width: 100%;
    position: absolute;
    top: -40px;
    img {
      height: 50px;
    }
  }
}
</style>
